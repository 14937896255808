import { graphql } from "gatsby"
import * as _ from "lodash/fp"
import * as React from "react"

import CardPost from "../components/CardPost"
import Page from "../components/Page"

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <Page>
      <main>
        {posts.map(({ node }) => {
          const title = _.getOr(
            node.frontmatter.title,
            "frontmatter.title",
            node
          )
          return (
            <div key={node.fields.slug}>
              {node.frontmatter.cover === null ? (
                <CardPost
                  link={node.fields.slug}
                  cover=""
                  title={title}
                  date={node.frontmatter.date}
                  excerpt={node.excerpt}
                />
              ) : (
                <CardPost
                  link={node.fields.slug}
                  cover={node.frontmatter.cover.childImageSharp.fluid.src}
                  title={title}
                  date={node.frontmatter.date}
                  excerpt={node.excerpt}
                />
              )}
            </div>
          )
        })}
      </main>
    </Page>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            cover {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
